body {
  overflow: hidden;
  flex: 1;
  min-height: -webkit-fill-available;
}
@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(./assets/fonts/AvenirNext-Bold.ttf) format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(./assets/fonts/AvenirNext-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(./assets/fonts/AvenirNext-DemiBold.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(./assets/fonts/AvenirNext-DemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(./assets/fonts/AvenirNext-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(./assets/fonts/AvenirNext-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(./assets/fonts/AvenirNext-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(./assets/fonts/AvenirNext-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "TiemposHeadlineWeb";
  src: local("TiemposHeadlineWeb"),
    url(./assets/fonts/TiemposHeadlineWeb-Semibold.ttf) format("truetype");
  font-weight: 400;
}
